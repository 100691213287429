import * as React from "react";
import { useCallback } from "react";
import { useStartMigration } from "../../../../hooks/api/admin/migrations/useStartMigration";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { AdminNavigation } from "../AdminNavigation";

export const MigrationsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const startMigration = useStartMigration();

    const onMigrationStartClicked = useCallback(() => {
        startMigration.mutation.mutate();
    }, [startMigration.mutation]);

    if (startMigration.mutation.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <AdminNavigation />
            <PrimaryButton disabled={false} onClick={onMigrationStartClicked}>
                Start Migration
            </PrimaryButton>
        </>
    );
};
