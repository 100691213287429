import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { getAxiosRequestConfig } from "../../../../functions/getAxiosRequestConfig";
import { useAuth } from "../../../useAuth";
import { AlertsContext, AlertType, IAlert } from "../../../../components/molecules/Alerts/AlertsContext";
import { Constants } from "../../../../services/Constants/Constants";

interface IResponse {}

const getMutationQuery = (token: string | null) => {
    const postRequest = async (): Promise<IResponse> => {
        const config = getAxiosRequestConfig(token);
        const request = axios.post(`${Constants.ApiRoot}/admin/migrations/start-migration`, {}, config);

        const { data } = await request;
        return data;
    };
    return postRequest;
};

export const useStartMigration = () => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);

    const mutation = useMutation(getMutationQuery(authToken), {
        onMutate: () => {},
        onSuccess: (_data) => {},
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
        },
    });
    return { mutation };
};
