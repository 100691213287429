import React from "react";
import { WithAuth } from "../../components/molecules/Auth/WithAuth";
import { MigrationsPage } from "../../components/pages/AdminPage/MigrationsPage/MigrationsPage";

const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <WithAuth adminOnly={true}>
            <MigrationsPage />
        </WithAuth>
    );
};

export default Page;
